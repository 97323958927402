@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Circular Pro";
  src: url("../public/fonts/CircularStd-Light.woff2") format("woff2"),
    url("../public/fonts/CircularStd-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Circular Pro";
  src: url("../public/fonts/circularstd-regular.woff2") format("woff2"),
    url("../public/fonts/circularstd-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Circular Pro";
  src: url("../public/fonts/CircularStd-Medium.woff2") format("woff2"),
    url("../public/fonts/CircularStd-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Circular Pro";
  src: url("../public/fonts/CircularStd-Bold.woff2") format("woff2"),
    url("../public/fonts/CircularStd-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Circular Pro";
  src: url("../public/fonts/CircularStd-Black.woff2") format("woff2"),
    url("../public/fonts/CircularStd-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ITC Cheltenham Std";
  src: url("../public/fonts/CheltenhamStd-BoldCond.woff2") format("woff2"),
    url("../public/fonts/CheltenhamStd-BoldCond.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ITC Cheltenham Std";
  src: url("../public/fonts/CheltenhamStd-BookCond.woff2") format("woff2"),
    url("../public/fonts/CheltenhamStd-BookCond.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ITC Cheltenham Std";
  src: url("../public/fonts/CheltenhamStd-LightCond.woff2") format("woff2"),
    url("../public/fonts/CheltenhamStd-LightCond.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ITC Cheltenham Std";
  src: url("../public/fonts/CheltenhamStd-UltraCond.woff2") format("woff2"),
    url("../public/fonts/CheltenhamStd-UltraCond.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ITC Cheltenham Std Book";
  src: url("../public/fonts/CheltenhamStd-Book.woff2") format("woff2"),
    url("../public/fonts/CheltenhamStd-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
}

input[type="radio"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  min-width: 20px;
  height: 20px;
  border: 2px solid #d1d1db;
  border-radius: 50%;
  transform: translateY(-0.075em);
}
input[type="radio"].radioClass {
  width: 110%;
  height: 110%;
}

input[type="radio"] {
  /* ...existing styles */

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

input[type="radio"] {
  /* ...existing styles */
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]::before {
  /* ...existing styles */

  /* Windows High Contrast Mode */
  background-color: #fff;
  border: 5px solid #1e3a8a;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  background: #f7f9fc;
  font-family: "Circular Pro", sans-serif;
}

/* custom-calendar */
.custom-calendar .rmdp-day.rmdp-today span,
.rmdp-day:not(.rmdp-disabled, .custom-calendar .rmdp-day-hidden) span:hover {
  background-color: #4c1d95;
}
.custom-calendar .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #1e3a8a;
}
.custom-calendar .rmdp-panel-body li,
.custom-calendar .rmdp-panel-body li .b-deselect {
  background-color: #4c1d95;
}
.custom-calendar .rmdp-panel-body li .b-deselect {
  padding-right: 5px;
}
